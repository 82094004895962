.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Hero {
  padding-top: 2vw;
  padding-bottom: 6.5rem;
  background: url("assets/hero-banner.png") bottom center no-repeat;
  color: #fff;
}

.HeroTopHeading {
  font-size: 4.5rem;
  line-height: 5rem;
  font-weight: 200;
  margin: 4.5rem 0 0 0;
}

.HeroTagline {
  font-size: 6rem;
  line-height: 6rem;
  font-weight: 800;
  margin: 0 0 2rem 0;
}

@media screen and (max-width: 960px) {
  .HeroTopHeading {
    font-size: 3rem;
    line-height: 3rem;
    font-weight: 200;
    margin: 4.5rem 0 0 0;
  }

  .HeroTagline {
    font-size: 4rem;
    line-height: 4rem;
    font-weight: 800;
    margin: 0 0 2rem 0;
  }
}

.SearchAndAnalytics {
  padding-top: 4.5rem;
  padding-bottom: 12rem;
  background: url("assets/drive-view.png") no-repeat;
  background-position: center right -100px;
  background-size: 732px;
}

@media screen and (max-width: 960px) {
  .SearchAndAnalytics {
    background-position: bottom -100px center;
    background-size: 100%;
    padding-bottom: 50vw;
  }
}

.DataExplosion {
  background: #2f2e2f url("assets/sensors.png") bottom center no-repeat;
  background-size: 100%;
  color: #fff;
  text-align: center;
  padding-top: 4.5rem;
  padding-bottom: 42vw;
}

.Scenario {
  text-align: center;
  background: #f5f5f5;
  padding-top: 4.5rem;
  padding-bottom: 5rem;
  border-bottom: 1rem solid #2f2e2f;
}

.ScenarioDivider {
  width: 30vw;
  margin-bottom: 2rem;
}

.TaggedVehicles {
  background: #fff url("assets/cars-tagged.png") bottom left no-repeat;
  background-size: 100%;
}

@media screen and (max-width: 960px) {
  .TaggedVehicles {
    padding-top: 3rem;
    padding-bottom: 4.5rem;
    background-image: none;
  }
}

.TaggedVehiclesText {
  background: #fff;
}

.SceneUnderstanding {
  background: #2f2e2f url("assets/search-screen.png") bottom center no-repeat;
  background-size: 100%;
  color: #fff;
  text-align: center;
  padding-top: 4.5rem;
  padding-bottom: 36vw;
}

.TagCategories {
  padding-top: 4.5rem;
  padding-bottom: 5rem;
}

.MicroServices {
  text-align: center;
  background: #f5f5f5 url("assets/micro-services-viz.svg") bottom center
    no-repeat;
  background-size: 100%;
  padding-top: 4.5rem;
  padding-bottom: 82vw;
}

.EasyDeployment {
  background-color: #5db8e1;
  color: #fff;
  padding-top: 4.5rem;
  padding-bottom: 5rem;
}

.Contact {
  background-color: #282c34;
  color: #fff;
  padding-top: 4.5rem;
  padding-bottom: 5rem;
}
